_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "Vui l\u00f2ng cung c\u1ea5p gi\u00e1 tr\u1ecb kh\u1edbp cho {0}",
	"crm.label.field":"Tr\u01b0\u1eddng",//no i18n
"crm.label.value":"Gia\u0301 tri\u0323",//no i18n
	"sentiment.criteria.wrongcriteria":"Gi\u00e1 tr\u1ecb ti\u00eau ch\u00ed kh\u00f4ng \u0111\u01b0\u1ee3c l\u1edbn h\u01a1n {0}",//no i18n
"crm.mb.field.common.splc":"Kh\u00f4ng \u0111\u01b0\u1ee3c ph\u00e9p s\u1eed d\u1ee5ng c\u00e1c k\u00fd t\u1ef1 \u0111\u1eb7c bi\u1ec7t. Nh\u1eadp m\u1ed9t gi\u00e1 tr\u1ecb h\u1ee3p l\u1ec7.",//no i18n
	"crm.label.field.plural":"c\u00e1c tr\u01b0\u1eddng",//no i18n
	"crm.label.in.minutes":"{0} (\u0111\u01a1n v\u1ecb ph\u00fat)",//no i18n
	"crm.security.roles.list":"Danh s\u00e1ch Vai tr\u00f2",//no i18n
"crm.security.roles.lookup.info":"Vui l\u00f2ng ch\u1ecdn m\u1ed9t vai tr\u00f2 t\u1eeb danh s\u00e1ch.",//no i18n
"crm.territory.addterritory":"Th\u00eam L\u00e3nh Th\u1ed5",//no i18n
"crm.title.edit.territory":"S\u1eeda L\u00e3nh Th\u1ed5",//no i18n
"crm.territory.title.assign.territories":"Ch\u1ec9 \u0110\u1ecbnh C\u00e1c L\u00e3nh Th\u1ed5",//no i18n
	"crm.label.context.help":"Tr\u1ee3 gi\u00fap",//no i18n
	"crm.label.from":"T\u1eeb",//no i18n
"crm.label.to":"\u0110\u1ebfn",//no i18n
"workflow.option.webhookFailure.fromDate":"T\u1eeb Ng\u00e0y",//no i18n
"workflow.option.webhookFailure.toDate":"\u0110\u1ebfn Ng\u00e0y",//no i18n
"crm.custom.field.less.than.equalto":"{0} ph\u1ea3i nh\u1ecf h\u01a1n ho\u1eb7c b\u1eb1ng {1}.",//no i18n
	"crm.template.listview.search.no.results":"Kh\u00f4ng t\u00ecm th\u1ea5y k\u1ebft qu\u1ea3",//No I18n
	"crm.label.tag.new":"Tag M\u1edbi",//No I18n
	"crm.label.enter.tag":"Nh\u1eadp Tag",//No I18n
	"crux.comboBox.max.limit":"B\u1ea1n kh\u00f4ng th\u1ec3 ch\u1ecdn nhi\u1ec1u h\u01a1n {0} {1}.",//NO I18n
	"Administrator":"Ng\u01b0\u1eddi Qu\u1ea3n tr\u1ecb",//No I18n
	"Standard":"Ti\u00eau chu\u1ea9n",//No I18n
	"crm.button.add":"Th\u00eam",//NO I18n
	"crm.wf.usage.date.criteria.error.msg":"T\u1eeb Ng\u00e0y kh\u00f4ng th\u1ec3 l\u00e0 ng\u00e0y sau \u0110\u1ebfn Ng\u00e0y.",//no i18n
	"crm.label.users":"Ng\u01b0\u1eddi d\u00f9ng", //NO I18n
  "crm.workflow.alert.roles":"Vai tr\u00f2", //NO I18n
  "crm.security.groups":"Nh\u00f3m", //NO I18n
	"crm.label.available" : "C\u00f3 s\u1eb5n", //NO I18n
	"crm.label.assign.manually" : "Ch\u1ec9 \u0111\u1ecbnh", //NO I18n
	"crm.globalsearch.option.all": "T\u1ea5t c\u1ea3", //NO I18n
	"webform.status.Active":"\u0110ang ho\u1ea1t \u0111\u1ed9ng", //NO I18n
	"Inactive":"Kh\u00f4ng ho\u1ea1t \u0111\u1ed9ng", //NO I18n
  "Confirmed":"\u0110\u00e3 x\u00e1c nh\u1eadn", //NO I18n
  "crm.user.component.unconfirmed":"Ch\u01b0a X\u00e1c Nh\u1eadn",//no i18n
  "DeletedUser":"\u0110\u00e3 x\u00f3a", //NO I18n
  "crm.feed.group.admin":"Qu\u1ea3n tr\u1ecb", //NO I18n
  "crm.ln.lable.current":"Hi\u1ec7n t\u1ea1i", //NO I18n
	"crm.label.selected": "\u0110\u01b0\u1ee3c ch\u1ecdn",//NO I18n
	"crm.auditlog.user": "Ng\u01b0\u1eddi d\u00f9ng", //NO I18n
	"cob.role": "Vai tro\u0300", //NO I18n
	"zoho.email": "Email", //NO I18n
	"Profile": "H\u1ed3 s\u01a1", //NO I18n
	"crm.security.group.users.empty": "Kh\u00f4ng t\u00ecm th\u1ea5y ng\u01b0\u1eddi d\u00f9ng n\u00e0o.", //NO I18n
	"crm.label.picklist.none": "Kh\u00f4ng c\u00f3", //NO I18n
	"crm.usrpop.non.selected" : "Nh\u1eefng Ng\u01b0\u1eddi D\u00f9ng \u0110\u00e3 Ch\u1ecdn",//NO I18n
	"crm.zti.label.user": "T\u00ean ng\u01b0\u1eddi d\u00f9ng", //NO I18n
	"crm.label.notSelected" : "Ch\u01b0a Ch\u1ecdn",//NO I18n
	"AM" : "S\u00c1",//NO I18n
	"Call" : "Cu\u1ed9c g\u1ecdi",//NO I18n
	"crm.phoneNo.Link.Title" : "G\u1ecdi b\u1eb1ng Skype",//NO I18n
	"crm.button.cancel" : "Hu\u0309y bo\u0309",//NO I18n
	"crm.button.save" : "L\u01b0u",//NO I18n
	"crm.no.data.found" : "Kh\u00f4ng t\u00ecm th\u1ea5y d\u1eef li\u1ec7u.",//NO I18n
	"crm.label.no.options.found" : "Kh\u00f4ng t\u00ecm th\u1ea5y t\u00f9y ch\u1ecdn.",//No I18n
	"crm.globalsearch.search.title" : "T\u00ecm ki\u1ebfm",//No I18n
	"None" : "Kh\u00f4ng",//No I18n
	"crm.label.criteria.pattern" : "M\u1eabu Ti\u00eau ch\u00ed quan tr\u1ecdng",//No I18n
	"crm.label.edit.criteria.pattern" : "S\u1eeda M\u00f4 Th\u1ee9c",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "C\u00e1c ngo\u1eb7c m\u00f4 th\u1ee9c kh\u00f4ng kh\u1edbp.",//No I18n
	"criteria.error.alert.brackets.invalid" : "D\u1ea5u ngo\u1eb7c kh\u00f4ng h\u1ee3p l\u1ec7 quanh (c\u00e1c) to\u00e1n t\u1eed \u0111i\u1ec1u ki\u1ec7n.",//No I18n
	"crm.criteria.number.notmatch.check" : "Vui l\u00f2ng ki\u1ec3m tra m\u00f4 th\u1ee9c t\u1ea1i {0}.",//No I18n
	"criteria.error.alert.other.params" : "N\u1ed9i dung kh\u00f4ng h\u1ee3p l\u1ec7 trong m\u00f4 th\u1ee9c n\u00e0y.", //No I18n
	"crm.label.search.for.users": "T\u00ecm Ki\u1ebfm Ng\u01b0\u1eddi D\u00f9ng", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "M\u00f4 Th\u1ee9c Ti\u00eau Ch\u00ed kh\u00f4ng kh\u1edbp v\u1edbi c\u00e1c \u0111i\u1ec1u ki\u1ec7n b\u1ea1n ch\u1ecdn.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "M\u00f4 Th\u1ee9c Ti\u00eau Ch\u00ed kh\u00f4ng kh\u1edbp v\u1edbi c\u00e1c \u0111i\u1ec1u ki\u1ec7n b\u1ea1n ch\u1ecdn.", //No I18n
	"and" : "v\u00e0", //No I18n
	"or" : "ho\u1eb7c", //No I18n
	"crm.label.or" : "Ho\u1eb7c", //No I18n
	"crm.label.and" : "V\u00e0", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Vui l\u00f2ng nh\u1eadp nh\u00e3n tr\u01b0\u1eddng h\u1ee3p l\u1ec7 v\u00e0o h\u00e0ng {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "Vui l\u00f2ng cho bi\u1ebft m\u1ed9t \u0111i\u1ec1u ki\u1ec7n h\u1ee3p l\u1ec7 cho {0}.", //No I18n
	"crm.field.valid.check" : "Vui l\u00f2ng nh\u1eadp m\u1ed9t {0} h\u1ee3p l\u1ec7.", //No I18n
	"crm.custom.field.less.than.to" : "Ph\u1ea1m vi <i>T\u1eeb</i> kh\u00f4ng \u0111\u01b0\u1ee3c l\u1edbn h\u01a1n ph\u1ea1m vi <i>\u0110\u1ebfn</i>." , //No I18n
	"crm.alert.label.savepattern" : "L\u01b0u m\u00f4 th\u1ee9c tr\u01b0\u1edbc khi thay \u0111\u1ed5i c\u00e1c ti\u00eau ch\u00ed.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "B\u1ea1n kh\u00f4ng th\u1ec3 th\u00eam c\u00e1c ti\u00eau ch\u00ed kh\u00e1c.",//No I18n
	"is" : "l\u00e0",//No I18n
	"isn\'t" : "kh\u00f4ng l\u00e0",//No I18n
	"contains" : "ch\u1ee9a \u0111\u1ef1ng",//No I18n
	"doesn\'t contain" : "kh\u00f4ng ch\u1ee9a",//No I18n
	"starts with" : "b\u1eaft \u0111\u1ea7u v\u1edbi",//No I18n
	"ends with" : "k\u1ebft th\u00fac v\u1edbi",//No I18n
	"is empty" : "c\u00f2n tr\u1ed1ng",//No I18n
	"is not empty" : "kh\u00f4ng tr\u1ed1ng",//No I18n
	"is before" : "l\u00e0 tr\u01b0\u1edbc \u0111\u00f3",//No I18n
	"is after" : "l\u00e0 sau \u0111\u00f3",//No I18n
	"between" : "gi\u1eefa",//No I18n
	"not between" : "kh\u00f4ng ph\u1ea3i gi\u1eefa",//No I18n
	"Today" : "H\u00f4m nay",//No I18n
	"Tommorow" : "Ng\u00e0y mai",//No I18n
	"Tommorow Onwards" : "B\u1eaft \u0111\u1ea7u v\u00e0o ng\u00e0y mai",//No I18n
	"Yesterday" : "H\u00f4m qua",//No I18n
	"Till Yesterday" : "\u0110\u1ebfn t\u1eadn H\u00f4m qua",//No I18n
	"Last Month" : "Th\u00e1ng tr\u01b0\u1edbc",//No I18n
	"Current Month" : "Th\u00e1ng n\u00e0y", //No I18n
	"Next Month" : "Th\u00e1ng sau", //No I18n
	"Last Week" : "Tu\u1ea7n tr\u01b0\u1edbc", //No I18n
	"Current Week" : "Tu\u1ea7n hi\u1ec7n nay", //No I18n
	"Next Week" : "Tu\u1ea7n t\u1edbi", //No I18n
	"Age in Days" : "Tu\u1ed5i theo Ng\u00e0y", //No I18n
	"Due in Days" : "S\u1ed1 ng\u00e0y s\u1ebd \u0111\u1ebfn h\u1ea1n", //No I18n
	"Scheduled" : "\u0110\u00e3 x\u1ebfp l\u1ecbch", //No I18n
	"Attended Dialled" : "\u0110\u00e3 G\u1ecdi C\u00f3 Ng\u01b0\u1eddi Nh\u1eadn", //No I18n
	"Unattended Dialled" : "\u0110\u00e3 G\u1ecdi Kh\u00f4ng C\u00f3 Ng\u01b0\u1eddi Nh\u1eadn", //No I18n
	"Overdue" : "Qu\u00e1 h\u1ea1n", //No I18n
	"Cancelled" : "\u0110\u00e3 h\u1ee7y", //No I18n
	"Received" : "\u0110\u00e3 nh\u1eadn \u0111\u01b0\u1ee3c", //No I18n
	"Missed" : "Nh\u1ee1", //No I18n
	"crm.alert.character.not.allowed" : "{0} kh\u00f4ng \u0111\u01b0\u1ee3c ph\u00e9p", //No I18n
	"crm.condition.in.last" : "trong cu\u1ed1i c\u00f9ng", //No I18n
	"crm.zinvoice.dueIn" : "h\u1ebft h\u1ea1n trong", //No I18n
	"on" : "V\u00e0o",//No I18n
	"before" : "Tr\u01b0\u1edbc \u0111\u00e2y",//No I18n
	"crm.label.general.small.after" : "sau khi",//No I18n
	"crm.thisweek" : "Tu\u1ea7n N\u00e0y",//No I18n
	"crm.label.this.month" : "Th\u00e1ng N\u00e0y",//No I18n
	"crm.thisyear" : "N\u0103m Nay",//No I18n
	"crm.source.user.and.system" : "Ng\u01b0\u1eddi D\u00f9ng & H\u1ec7 Th\u1ed1ng",//No I18n
	"crm.source.user.or.system" : "Ng\u01b0\u1eddi D\u00f9ng Ho\u1eb7c H\u1ec7 Th\u1ed1ng",//No I18n
	"crm.label.system2" : "H\u1ec7 th\u1ed1ng",//No I18n
	"crm.source.user.only" : "Ch\u1ec9 Theo Ng\u01b0\u1eddi D\u00f9ng",//No I18n
	"crm.source.system.only" : "Ch\u1ec9 Theo H\u1ec7 Th\u1ed1ng",//No I18n
	"crm.condition.till.today" : "Cho \u0110\u1ebfn H\u00f4m Nay",//No I18n
	"game.month.left" : "1 th\u00e1ng",//No I18n
	"game.months.left" : "{0} th\u00e1ng",//No I18n
	"crm.condition.last.30.days" : "trong 30 ng\u00e0y v\u1eeba qua",//No I18n
	"crm.condition.last.60.days" : "trong 60 ng\u00e0y v\u1eeba qua",//No I18n
	"crm.condition.last.90.days" : "trong 90 ng\u00e0y v\u1eeba qua",//No I18n
	"crm.label.filter.typehere" : "Nh\u1eadp \u1edf \u0111\u00e2y", //No I18N
	"crm.filter.is.not" : "kh\u00f4ng l\u00e0", //No I18n
	"crm.condition.until.now" : "Cho \u0110\u1ebfn B\u00e2y Gi\u1edd",//No I18n
	"crm.filter.email.isblocked" : "b\u1ecb ch\u1eb7n",//No I18n
	"crm.filter.email.isnotblocked" : "kh\u00f4ng b\u1ecb ch\u1eb7n",//No I18n
	"crm.label.no.results.match" : "Kh\u00f4ng t\u00ecm th\u1ea5y k\u1ebft qu\u1ea3 kh\u1edbp",//No I18n
	"crm.label.select.user" : "Nh\u1ea5p v\u00e0o \u0111\u1ec3 Ch\u1ecdn Ng\u01b0\u1eddi D\u00f9ng.", //No I18n
	"current.logged.in.user": "Ng\u01b0\u1eddi D\u00f9ng \u0110\u00e3 \u0110\u0103ng Nh\u1eadp", //NO I18n
	"current.logged.in.user.definition": "Ng\u01b0\u1eddi d\u00f9ng b\u1eaft \u0111\u1ea7u Thao T\u00e1c B\u1ea3n Ghi.", //NO i18n
	"crm.security.group": "Nh\u00f3m", //NO I18n
	"crm.security.role": "Vai tr\u00f2", //NO I18n
	"Date" : "Ng\u00e0y",//No I18n
	"crm.field.valid.decimal.check2" : "C\u00e1c h\u00e0ng th\u1eadp ph\u00e2n cho tr\u01b0\u1eddng <i>{0}</i> ph\u1ea3i nh\u1ecf h\u01a1n ho\u1eb7c b\u1eb1ng {1}.",//No I18n
	"crm.field.empty.check" : "{0} kh\u00f4ng \u0111\u01b0\u1ee3c \u0111\u1ec3 tr\u1ed1ng.",//No I18n
	"crm.label.add.note": "Th\u00eam Ghi ch\u00fa", //NO I18n
	"crm.label.simply.by": "b\u1edfi", //NO I18n
	"crm.general.addnote": "Th\u00eam ghi ch\u00fa", //NO I18n
	"crm.general.addtitle": "Th\u00eam ti\u00eau \u0111\u1ec1", //NO I18n
	"crm.label.attach.file": "K\u00e8m theo File", //NO I18N
	"crm.button.mass.delete": "Xo\u00e1", //NO I18N
	"crm.warning.delete.record": "B\u1ea1n c\u00f3 ch\u1eafc mu\u1ed1n di chuy\u1ec3n \"{0}\" v\u00e0o Th\u00f9ng R\u00e1c hay kh\u00f4ng?", //NO I18N
	"crm.label.yes": "\u0110\u00fang", //NO I18N
	"crm.note.view.previous": "Xem C\u00e1c Ghi Ch\u00fa Tr\u01b0\u1edbc \u0110\u00f3", //NO I18N
  "of": "c\u1ee7a", //NO I18N
	"crm.label.notes": "Ghi ch\u00e9p", //NO I18N
	"crm.note.recent.first": "\u0110\u1ea7u Ti\u00ean G\u1ea7n \u0110\u00e2y", //NO I18N
	"crm.note.recent.last": "Cu\u1ed1i C\u00f9ng G\u1ea7n \u0110\u00e2y", //NO I18N
	"crm.territory.label.only": "ch\u1ec9 \u0111\u1ed1i v\u1edbi {0}", //no i18n
	"crm.select" : "Ch\u1ecdn",//No I18n
	"crm.button.apply.filter" : "\u00c1p d\u1ee5ng L\u1ecdc",//No I18n
	"crm.alert.maximum.text.values.contains" : "B\u1ea1n kh\u00f4ng th\u1ec3 nh\u1eadp nhi\u1ec1u h\u01a1n {0} gi\u00e1 tr\u1ecb cho tr\u01b0\u1eddng n\u00e0y.",//No I18n
	"PM" : "CH",//No I18n
	"crm.mb.newversion.msg4" : "T\u00f4i hi\u1ec3u!",//No I18n
	"Jan" : "Th\u00e1ng 1",//No I18n
	"Feb" : "Th\u00e1ng 2",//No I18n
	"Mar" : "Th\u00e1ng 3",//No I18n
	"Apr" : "Th\u00e1ng 4",//No I18n
	"Jun" : "Th\u00e1ng 6",//No I18n
	"Jul" : "Th\u00e1ng 7",//No I18n
	"Aug" : "Th\u00e1ng 8",//No I18n
	"Sep" : "Th\u00e1ng 9",//No I18n
	"Oct" : "Th\u00e1ng 10",//No I18n
	"Nov" : "Th\u00e1ng 11",//No I18n
	"Dec" : "Th\u00e1ng 12",//No I18n
	"crm.label.More" :"H\u01a1n n\u1eefa", //no i18n
   "crm.label.unmapped.stages":"Kh\u00f4ng \u0111\u01b0\u1ee3c gi\u1ea3i tr\u00ecnh", //no i18n
   "crm.wf.summary.label.ShowInstantActions" : "Hi\u1ec7n nhi\u1ec1u h\u01a1n",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Hi\u1ec7n \u00edt h\u01a1n",//No I18n

	 //filter related keys-start
	"crm.lead.prediction.convert.high":"Cao",//no i18n
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.convert.medium":"Trung b\u00ecnh",//no i18n
"crm.lead.prediction.convert.low":"Th\u1ea5p",//no i18n
"crm.lead.prediction.tooltip.convert":"KH\u1ea2 N\u0102NG",//no i18n
"crm.lead.prediction.tooltip.score":"\u0110I\u1ec2M",//no i18n
"Planned":"\u0110\u00e3 l\u1eadp k\u1ebf ho\u1ea1ch",//no i18n
"Invited":"\u0110\u00e3 m\u1eddi",//no i18n
"Sent":"\u0110\u00e3 g\u1eedi",//no i18n
"Received":"\u0110\u00e3 nh\u1eadn \u0111\u01b0\u1ee3c",//no i18n
"Opened":"\u0110\u00e3 m\u1edf",//no i18n
"Responded":"\u0110\u00e3 tr\u1ea3 l\u1eddi",//no i18n
"Bounced":"\u0110\u00e3 b\u1ecb tr\u1ea3 l\u1ea1i",//no i18n
"Opted\ Out":"\u0110\u00e3 ch\u1ecdn kh\u00f4ng nh\u1eadn",//no i18n
"crm.filter.label.with.open":"C\u00f3 M\u1edf {0}",//no i18n
"crm.filter.label.without.open":"Kh\u00f4ng C\u00f3 {0} M\u1edf",//no i18n
"crm.filter.label.without.any":"Kh\u00f4ng C\u00f3 B\u1ea5t K\u1ef3 {0} N\u00e0o",//no i18n
"crm.filter.label.with.module":"C\u00f3 {0}",//no i18n
"crm.filter.label.activity.due":"{0} \u0110\u1ebfn h\u1ea1n",//no i18n
"crm.filter.label.activity.done":"{0} Ho\u00e0n t\u1ea5t",//no i18n
"Notes":"Ghi ch\u00fa",//no i18n
"crm.filter.label.notes.added":"\u0110\u00e3 Th\u00eam Ghi Ch\u00fa",//no i18n
"crm.label.filter.email.status":"Tr\u1ea1ng Th\u00e1i Email M\u1edbi Nh\u1ea5t",//no i18n
"crm.label.filter.email.clicked":"\u0111\u00e3 nh\u1ea5p",//no i18n
"crm.label.filter.email.responded":"\u0111\u00e3 tr\u1ea3 l\u1eddi", //no i18n
"crm.label.filter.email.info":"L\u1ecdc c\u00e1c b\u1ea3n ghi d\u1ef1a tr\u00ean tr\u1ea1ng th\u00e1i cu\u1ed1i c\u00f9ng c\u1ee7a c\u00e1c email b\u1ea1n \u0111\u00e3 g\u1eedi/nh\u1eadn.",//no i18n
"crm.filter.label.sent":"\u0111\u00e3 g\u1eedi",//no i18n
"crm.filter.label.not.sent":"ch\u01b0a g\u1eedi",//no i18n
"crm.filter.label.opened":"\u0111\u00e3 m\u1edf",//no i18n
"crm.filter.label.not.opened":"ch\u01b0a m\u1edf",//no i18n
"crm.filter.label.received":"\u0111\u00e3 nh\u1eadn",//no i18n
"crm.filter.label.not.received":"ch\u01b0a nh\u1eadn",//no i18n
"crm.filter.label.bounced":"\u0111\u00e3 g\u1eedi l\u1ea1i",//no i18n
"crm.filter.label.opened.not.replied":"\u0111\u00e3 m\u1edf v\u00e0 ch\u01b0a tr\u1ea3 l\u1eddi", //no i18n
"crm.filter.label.any":"B\u1ea5t k\u1ef3 m\u1ee5c n\u00e0o tr\u00ean \u0111\u00e2y",//no i18n
"crm.zia.config.potential.amount":"{0} L\u01b0\u1ee3ng",//no i18n
"Quote\ Stage":"Giai \u0111o\u1ea1n {0}",//no i18n
"crm.module.owner":"Ch\u1ee7 s\u1edf h\u1eefu {0}",//no i18n
"Potential\ Closing\ Date":"Ng\u00e0y k\u1ebft th\u00fac {0}",//no i18n
"crm.lead.prediction.likely.convert":"C\u00f3 kh\u1ea3 n\u0103ng s\u1ebd chuy\u1ec3n \u0111\u1ed5i",//no i18n
"crm.predictions.feature.label":"D\u1ef1 \u0111o\u00e1n",//no i18n
"crm.intelligence.prediction.likelywin":"C\u00f3 Kh\u1ea3 N\u0103ng Th\u1eafng",//no i18n
"crm.intelligence.prediction.likelylose":"C\u00f3 Kh\u1ea3 N\u0103ng Thua",//no i18n
"crm.intelligence.prediction.halfchance":"X\u00e1c Su\u1ea5t 50:50",//no i18n
"crm.intelligence.prediction.score":"\u0110i\u1ec3m Ch\u00ednh X\u00e1c",//no i18n
"crm.lead.prediction.recent.score":"\u0110i\u1ec3m d\u1ef1 \u0111o\u00e1n g\u1ea7n \u0111\u00e2y",//no i18n
"crm.intelligence.prediction.lastconv":"3 n\u1ed9i dung n\u00f3i chuy\u1ec7n cu\u1ed1i c\u00f9ng",//no i18n
"crm.intelligence.prediction.recordsfocus":"C\u00e1c b\u1ea3n ghi c\u1ea7n t\u1eadp trung",//no i18n
"crm.intelligence.prediction.slowmoving":"Bi\u1ebfn Chuy\u1ec3n Ch\u1eadm",//no i18n
"crm.intelligence.prediction.trend.down":"Gi\u1ea3m G\u1ea7n \u0110\u00e2y",//no i18n
"crm.label.touched.records":"C\u00e1c B\u1ea3n Ghi \u0110\u00e3 Ch\u1ea1m \u0110\u1ebfn",//no i18n
"crm.label.untouched.records":"C\u00e1c B\u1ea3ng Ghi Ch\u01b0a Ch\u1ea1m \u0110\u1ebfn",//no i18n
"crm.label.record.action":"Thao T\u00e1c B\u1ea3n Ghi",//no i18n
"workflow.rule.view.label.Modified":"\u0110\u00e3 b\u1ed5 sung",//no i18n
"crm.label.not.modified":"Ch\u01b0a \u0110i\u1ec1u Ch\u1ec9nh",//no i18n
"crm.label.related.records.action":"Thao T\u00e1c B\u1ea3n Ghi Li\u00ean Quan",//no i18n
"Done":"Ho\u00e0n th\u00e0nh",//no i18n
"crm.label.not.done":"Ch\u01b0a Ho\u00e0n T\u1ea5t",//no i18n
"sentiment.model":"C\u1ea3m Ngh\u0129 Email",//no i18n
"sentiment.criteria.count":"S\u1ed1",//no i18n
"sentiment.criteria.percentage":"Ph\u1ea7n tr\u0103m",//no i18n
"sentiment.criteria.lastmail":"\u0110\u1ed1i v\u1edbi email cu\u1ed1i c\u00f9ng",//no i18n
"Chats":"Chat",//no i18n
"Attended":"\u0110\u00e3 tham d\u1ef1",//no i18n
"crm.lead.prediction.popup.text":"Khi c\u00f3 kh\u1ea3 n\u0103ng chuy\u1ec3n \u0111\u1ed5i l\u00e0 {0}, \u0111i\u1ec3m d\u1ef1 \u0111o\u00e1n s\u1ebd n\u1eb1m trong kho\u1ea3ng {1}.",//no i18n
"crm.lead.prediction.popup.final":"Vui l\u00f2ng thay \u0111\u1ed5i b\u1ed9 l\u1ecdc theo \u0111\u00f3 v\u00e0 th\u1eed l\u1ea1i.",//no i18n
"crm.custom.field.less.than.to1":"Ph\u1ea1m vi T\u1eeb kh\u00f4ng \u0111\u01b0\u1ee3c l\u1edbn h\u01a1n ph\u1ea1m vi \u0110\u1ebfn.",//no i18n
"Last\ Activity\ Date":"Ng\u00e0y Ho\u1ea1t \u0110\u1ed9ng Cu\u1ed1i C\u00f9ng",//no i18n
"crm.label.vendor.name":"T\u00ean {0}",//no i18n
"hours":"gi\u1edd",//no i18n
"days":"Ng\u00e0y",//no i18n
"weeks":"Tu\u1ea7n",//no i18n
"months":"Th\u00e1ng",//no i18n
"years":"n\u0103m",//no i18n
"crm.label.general.small.after":"sau khi",//no i18n
"Last\ Week":"Tu\u1ea7n tr\u01b0\u1edbc",//no i18n
"Last\ Month":"Th\u00e1ng tr\u01b0\u1edbc",//no i18n
"crm.module.name":"T\u00ean {0}",//no i18n
"Campaign":"Chi\u1ebfn d\u1ecbch",//no i18n
"Tasks":"C\u00f4ng vi\u1ec7c",//no i18n
"Calls":"Cu\u1ed9c g\u1ecdi",//no i18n
"Events":"S\u1ef1 ki\u1ec7n",//no i18n
"sentiment.criteria.wrongcriteria":"Gi\u00e1 tr\u1ecb ti\u00eau ch\u00ed kh\u00f4ng \u0111\u01b0\u1ee3c l\u1edbn h\u01a1n {0}",//no i18n

"crm.chosen.minimum.input.text":"Vui l\u00f2ng nh\u1eadp th\u00eam {0} k\u00fd t\u1ef1",//no i18n
"crm.intelligence.prediction.trendup":"T\u0103ng",//no i18n
"crm.intelligence.prediction.trenddown":"Gi\u1ea3m",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"Ho\u00e0n th\u00e0nh" ,//no i18n
"crm.label.success":"Th\u00e0nh c\u00f4ng" ,//no i18n
"crm.label.Failure":"Th\u1ea5t b\u1ea1i" ,//no i18n
"Both":"C\u1ea3 hai" ,//no i18n
"crm.condition.cannot.empty":"Kh\u00f4ng \u0111\u01b0\u1ee3c \u0111\u1ec3 tr\u1ed1ng \u0111i\u1ec1u ki\u1ec7n.",//no i18n
"crm.condition.last.30.days":"trong 30 ng\u00e0y v\u1eeba qua",//no i18n
"crm.condition.last.60.days":"trong 60 ng\u00e0y v\u1eeba qua",//no i18n
"crm.condition.last.90.days":"trong 90 ng\u00e0y v\u1eeba qua",//no i18n
"crm.sentiment.Positive":"T\u00edch c\u1ef1c",//no i18n
"crm.sentiment.Negative":"Ti\u00eau c\u1ef1c",//no i18n
"sentiment.positiveandnegative":"D\u01b0\u01a1ng v\u00e0 \u00c2m",//no i18n
"sentiment.positiveornegative":"D\u01b0\u01a1ng ho\u1eb7c \u00c2m",//no i18n
"sentiment.positiveonly":"Ch\u1ec9 D\u01b0\u01a1ng",//no i18n
"sentiment.negativeonly":"Ch\u1ec9 \u00c2m",//no i18n
"crm.sentiment.Neutral":"Trung h\u00f2a",//no i18n
"crm.filters.select.campaign.type":"Ch\u1ecdn Lo\u1ea1i {0}",//no i18n
"crm.filters.select.campaign.status":"Ch\u1ecdn Tr\u1ea1ng Th\u00e1i {0}",//no i18n
"campaign.Member" : "Th\u00e0nh vi\u00ean",//no i18n
	"Service":"D\u1ecbch v\u1ee5",//no i18n
"Activities":"Ho\u1ea1t \u0111\u1ed9ng",//no i18n
"crm.livedesk.pot.nextdays":"{0} ng\u00e0y t\u1edbi",//no i18n
"Today\ +\ Overdue":"H\u00f4m nay + Qu\u00e1 h\u1ea1n",//no i18n
"crm.source.user.and.system":"Ng\u01b0\u1eddi D\u00f9ng & H\u1ec7 Th\u1ed1ng",//no i18n
"crm.source.user.or.system":"Ng\u01b0\u1eddi D\u00f9ng Ho\u1eb7c H\u1ec7 Th\u1ed1ng",//no i18n
"User":"Ng\u01b0\u1eddi s\u1eed d\u1ee5ng",//no i18n
"crm.source.user.only":"Ch\u1ec9 Theo Ng\u01b0\u1eddi D\u00f9ng",//no i18n
"crm.source.system.only":"Ch\u1ec9 Theo H\u1ec7 Th\u1ed1ng",//no i18n
"Scheduled":"\u0110\u00e3 x\u1ebfp l\u1ecbch",//no i18n
"Attended\ Dialled":"\u0110\u00e3 G\u1ecdi C\u00f3 Ng\u01b0\u1eddi Nh\u1eadn",//no i18n
"Unattended\ Dialled":"\u0110\u00e3 G\u1ecdi Kh\u00f4ng C\u00f3 Ng\u01b0\u1eddi Nh\u1eadn",//no i18n
"Cancelled":"\u0110\u00e3 h\u1ee7y",//no i18n
"crm.filter.email.isblocked":"b\u1ecb ch\u1eb7n",//no i18n
"crm.filter.email.isnotblocked":"kh\u00f4ng b\u1ecb ch\u1eb7n",//no i18n
"condition.till.now":"Cho \u0110\u1ebfn B\u00e2y Gi\u1edd",//no i18n
"crm.recurring.no.months":"{0} th\u00e1ng",//no i18n
"crm.lead.prediction.tooltip":"C\u00f3 kh\u1ea3 n\u0103ng chuy\u1ec3n \u0111\u1ed5i - Ph\u1ea1m Vi \u0110i\u1ec3m",//no i18n
"crm.website.activity":"Ho\u1ea1t \u0110\u1ed9ng Trang Web",//no i18n
"crm.label.By":"Theo",//no i18n
"crm.chosen.searching.text":"\u0110ang t\u00ecm ki\u1ebfm...",//no i18n
"crm.label.memberstatus.is":"v\u00e0 Tr\u1ea1ng Th\u00e1i Th\u00e0nh Vi\u00ean l\u00e0",//no i18n
"crm.events.duration":"Kho\u1ea3ng th\u1eddi gian",//no i18n
"crm.title.clear.name":"Xo\u00e1 b\u1ecf",//no i18n
"crm.label.status.is":"v\u00e0 tr\u1ea1ng th\u00e1i l\u00e0",//no i18n
"zia.last3.help":"N\u1ed9i dung n\u00f3i chuy\u1ec7n bao g\u1ed3m c\u00e1c cu\u1ed9c g\u1ecdi, t\u00e1c v\u1ee5, {0}, email \u0111\u00e3 nh\u1eadn, ghi ch\u00fa, l\u01b0\u1ee3t truy c\u1eadp, b\u00ecnh lu\u1eadn tr\u00ean m\u1ea1ng x\u00e3 h\u1ed9i, y\u00eau c\u1ea7u h\u1ed7 tr\u1ee3 t\u1eeb b\u1ed9 ph\u1eadn tr\u1ee3 gi\u00fap.",//no i18n
"crm.label.tag.related.to":"li\u00ean quan \u0111\u1ebfn",//no i18n


	//filter related keys-End
	"crm.label.account.created" : "{0} M\u1edbi s\u1ebd \u0111\u01b0\u1ee3c t\u1ea1o.",//No I18n
	"crm.krp.no.records.found" : "Kh\u00f4ng t\u00ecm th\u1ea5y {0}",//No I18n
	"crm.module.new" : "{0} M\u1edbi",//No I18n
	"crm.label.view" : "Nh\u00ecn",//No I18n
	"crm.nsocial.customers" : "Kha\u0301ch ha\u0300ng",//No I18n
	"crm.nsocial.open.potential" : "M\u1edf {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Kh\u00e1c",//No i18n
	"crm.field.length.check" : "Gi\u00e1 tr\u1ecb {0} v\u01b0\u1ee3t qu\u00e1 chi\u1ec1u d\u00e0i t\u1ed1i \u0111a.", //No I18n
	"crm.lower.now": "b\u00e2y gi\u1edd",//no i18n
	"crm.time.min.ago": "{0} ph\u00fat tr\u01b0\u1edbc",//no i18n
	"crm.time.mins.ago":" {0} ph\u00fat tr\u01b0\u1edbc",//no i18n
	"crm.time.hr.ago": "{0} gi\u1edd tr\u01b0\u1edbc",//no i18n
	"crm.time.hrs.ago": "{0} gi\u1edd tr\u01b0\u1edbc", //no i18n
	"AllUsers": "T\u1ea5t c\u1ea3 ng\u01b0\u1eddi S\u1eed d\u1ee5ng", //no i18n
	"crm.label.search":"T\u00ecm ki\u1ebfm",//no i18n
	"crm.api.filterby":"L\u1ecdc theo",//no i18n
	"crm.customview.nofields.found":"--Kh\u00f4ng C\u00f3 Tr\u01b0\u1eddng Kh\u1edbp--",//no i18n
	"crm.setup.system.ziarecommendation":"\u0110\u1ec1 ngh\u1ecb",//no i18n
	"crm.filter.label.all.products":"T\u1ea5t c\u1ea3 {0}",//no i18n
	"crm.filter.label.select.products":"{0} \u0111\u00e3 ch\u1ecdn",//no i18n
	"crm.reviewprocess.smart.filter":"Tr\u1ea1ng Th\u00e1i B\u1ea3n Ghi Quy Tr\u00ecnh Xem X\u00e9t",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Vui l\u00f2ng ch\u1ecdn m\u1ed9t gi\u00e1 tr\u1ecb.",//no i18n
	"crm.segmentation.segment.score":"\u0110i\u1ec3m Ph\u00e2n Kh\u00fac",//no i18n
	"crm.filter.label.in":"d\u1ef1a tr\u00ean",//no i18n
	"crm.filter.label.and.purchase.in":"v\u00e0 C\u00f3 Kh\u1ea3 N\u0103ng Mua trong",//no i18n
	"crm.filter.label.last.purchased":"v\u00e0 g\u1ea7n \u0111\u00e2y \u0111\u00e3 mua",//no i18n
	"crm.filter.label.a.day":"m\u1ed9t Nga\u0300y",//no i18n
	"crm.filter.label.a.week":"m\u1ed9t Tu\u1ea7n",//no i18n
	"crm.filter.label.a.month":"m\u1ed9t Th\u00e1ng",//no i18n
	"crm.cal.custom":"T\u00f9y ch\u1ec9nh",//no i18n
	"crm.mb.field.common.empt":"Kh\u00f4ng th\u1ec3 \u0111\u1ec3 tr\u1ed1ng gi\u00e1 tr\u1ecb n\u00e0y.",//no i18n
	"crm.chosen.error.loading.text":"R\u1ea5t ti\u1ebfc, ch\u00fang t\u00f4i kh\u00f4ng th\u1ec3 t\u1ea3i k\u1ebft qu\u1ea3 c\u1ee7a b\u1ea1n",//no i18n
	"crm.filter.label.firstbuy":"L\u1ea7n \u0111\u1ea7u",//no i18n
	"crm.filter.label.cwbab":"Ng\u01b0\u1eddi ph\u1ee5 thu\u1ed9c",//no i18n
	"crm.filter.label.fbt":"G\u00f3i",//no i18n
	"crm.filter.label.rebuy":"L\u1eb7p l\u1ea1i",//no i18n
	"crm.filter.label.nextbuy":"Tri\u0300nh t\u01b0\u0323",//no i18n
	"crm.mxnlookup.select" : "Ch\u1ec9 \u0111\u1ecbnh {0}",//No I18n
	"crm.lookup.chooserecord":"Cho\u0323n {0}",//no i18n
	"crm.record.selected":"{0} \u0111\u00e3 ch\u1ecdn",//no i18n
	"crm.module.empty.message" : "Kh\u00f4ng t\u00ecm th\u1ea5y {0}",//No I18n
	"crm.mxnlookup.selected" : "\u0110\u00e3 ch\u1ec9 \u0111\u1ecbnh {0}",//No I18n
	"crm.label.creator.noPermission" : "T\u1eeb ch\u1ed1i Quy\u1ec1n", //No I18n
	"crm.security.error" : "B\u1ea1n kh\u00f4ng c\u00f3 \u0111\u1ee7 quy\u1ec1n \u0111\u1ec3 th\u1ef1c hi\u1ec7n thao t\u00e1c n\u00e0y. H\u00e3y li\u00ean h\u1ec7 v\u01a1\u0301i qu\u1ea3n tr\u1ecb vi\u00ean c\u1ee7a b\u1ea1n." ,//No I18n
	"crm.segmentation.recency" : "G\u1ea7n \u0111\u00e2y", //No I18n
	"crm.segmentation.frequency" : "T\u1ea7n su\u1ea5t", //No I18n
	"crm.segmentation.monetary" : "Ti\u1ec1n t\u1ec7", //No I18n
	"crm.smartfilter.related.module.msg" : "B\u1ea1n kh\u00f4ng th\u1ec3 ch\u1ecdn nhi\u1ec1u h\u01a1n ba m\u00f4-\u0111un li\u00ean quan." , //No I18n
	"crm.smartfilter.related.module.msg1" : "(Vd: Email, Ho\u1ea1t \u0110\u1ed9ng, Ghi Ch\u00fa)", //No I18n
	"crm.smartfilter.related.module.msg2" : "Kh\u00f4ng \u0111\u01b0\u1ee3c \u0111\u1ec3 tr\u1ed1ng th\u1eddi l\u01b0\u1ee3ng", //No I18n
	"crm.label.timeZone": "Khu v\u1ef1c \u0110\u1ed3ng h\u1ed3", //NO I18n
	"crm.label.insufficient.privileges": "Kh\u00f4ng \u0111\u1ee7 \u0110\u1eb7c Quy\u1ec1n \u0111\u1ec3 th\u1ef1c hi\u1ec7n thao t\u00e1c n\u00e0y. Li\u00ean h\u1ec7 v\u01a1\u0301i Qu\u1ea3n Tr\u1ecb Vi\u00ean c\u1ee7a b\u1ea1n.", //NO I18n
	"crm.filter.header.secton.system": "C\u00e1c B\u1ed9 L\u1ecdc Do H\u1ec7 Th\u1ed1ng X\u00e1c \u0110\u1ecbnh", //NO I18N
	"crm.filter.header.secton.fields": "L\u1ecdc Theo Tr\u01b0\u1eddng", //NO I18N
	"crm.createfield.calcinfo.new" : "Tr\u01b0\u1eddng n\u00e0y \u0111\u00f3ng vai tr\u00f2 nh\u01b0 m\u00e1y t\u00ednh cho b\u1ea5t k\u1ef3 bi\u1ec3u th\u1ee9c n\u00e0o b\u1ea1n nh\u1eadp.</br> <b>V\u00ed d\u1ee5 20+20</b> s\u1ebd t\u1ef1 \u0111\u1ed9ng cho ra <b>40</b>",//No i18n
	"crm.lable.read.only" : "Tr\u01b0\u1eddng ch\u1ec9 \u0111\u1ecdc",//No i18n
	"crm.column.sort.asc" : "T\u0103ng d\u1ea7n",//No I18n
	"crm.column.sort.desc" : "Gi\u1ea3m d\u1ea7n",//No i18n
	"crm.column.unsort" : "Ch\u01b0a s\u1eafp x\u1ebfp",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Chia s\u1ebb v\u1edbi kh\u00e1ch h\u00e0ng", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "Ch\u1ec9 cho ph\u00e9p {0} k\u00fd t\u1ef1 \u0111\u1ed1i v\u1edbi {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "\u0110\u00e3 chia s\u1ebb v\u1edbi Kh\u00e1ch H\u00e0ng", //NO I18N
	"crm.button.ok" : "OK (\u0110\u1ed1ng \u00fd)", //NO I18N
	"crm.role.already.selected" : "Vai tr\u00f2 n\u00e0y \u0111\u00e3 \u0111\u01b0\u1ee3c ch\u1ecdn", //no i18n
	"crm.user.deleted": "\u0110\u00c3 X\u00d3A NG\u01af\u1edcI D\u00d9NG",  //NO I18N
	"crm.account.closed": "T\u00c0I KHO\u1ea2N N\u00c0Y \u0110\u00c3 B\u1eca \u0110\u00d3NG",  //NO I18N
	"crm.start.chat": "B\u1eaft \u0111\u1ea7u chat",  //NO I18N
	"crm.start.call": "B\u1eaft \u0111\u1ea7u cu\u1ed9c g\u1ecdi",  //NO I18N
	"crm.recipient.invalid.email" : "T\u00ecm th\u1ea5y email kh\u00f4ng h\u1ee3p l\u1ec7.", //NO I18N
	"crm.recipient.add.recipient" : "Th\u00eam Ng\u01b0\u1eddi Nh\u1eadn B\u1ed5 Sung", //NO I18N
	"crm.start.video.call": "B\u1eaft \u0111\u1ea7u cu\u1ed9c g\u1ecdi video",  //NO I18N //ignorei18n_start

	"crm.label.scoring.rules":"Quy T\u1eafc Ch\u1ea5m \u0110i\u1ec3m",
	"Score":"\u0110i\u1ec3m",
	"Positive Score":"\u0110i\u1ec3m D\u01b0\u01a1ng",
	"Negative Score":"\u0110i\u1ec3m \u00c2m",
	"Touch Point Score":"\u0110i\u1ec3m S\u1ed1 \u0110i\u1ec3m Ti\u1ebfp X\u00fac",
	"Positive Touch Point Score":"\u0110i\u1ec3m S\u1ed1 \u0110i\u1ec3m Ti\u1ebfp X\u00fac D\u01b0\u01a1ng",
	"Negative Touch Point Score":"\u0110i\u1ec3m S\u1ed1 \u0110i\u1ec3m Ti\u1ebfp X\u00fac \u00c2m",
	"crm.label.type.minutes": "Nh\u1eadp \u1edf \u0111\u00e2y theo \u0111\u01a1n v\u1ecb ph\u00fat", //NO I18N

	"is\ OPEN":"l\u00e0 M\u1ede",//no i18n
	"is\ WON":"l\u00e0 \u0110\u00c3 GI\u00c0NH \u0110\u01af\u1ee2C",//no i18n
	"is\ LOST":"l\u00e0 \u0110\u00c3 M\u1ea4T",//no i18n
	"crm.potential.all.open":"T\u1ea5t C\u1ea3 C\u00e1c Giai \u0110o\u1ea1n M\u1edf",//no i18n
	"crm.potential.all.won":"T\u1ea5t C\u1ea3 C\u00e1c Giai \u0110o\u1ea1n \u0110\u00e3 Gi\u00e0nh \u0110\u01b0\u1ee3c \u0110\u00e3 \u0110\u00f3ng",//no i18n

	"crm.potential.all.lost":"T\u1ea5t C\u1ea3 C\u00e1c Giai \u0110o\u1ea1n \u0110\u00e3 M\u1ea5t \u0110\u00e3 \u0110\u00f3ng",//no i18n
	"crm.campaign.member.status" : "Tr\u1ea1ng th\u00e1i Th\u00e0nh vi\u00ean",//no i18n
	"crm.dashboard.select.type" : "Ch\u1ecdn {0}",//no i18n
	"crm.campaign.service.status":"Tr\u1ea1ng Th\u00e1i D\u1ecbch V\u1ee5",//no i18n

	"crm.label.addColumn":"Th\u00eam C\u1ed9t",//no i18n
	"crm.button.clear.filter":"\u0110\u00f3ng B\u1ed9 L\u1ecdc",//no i18n
	"crm.button.show.filter":"Hi\u1ec3n Th\u1ecb B\u1ed9 L\u1ecdc",//no i18n
	"crm.las.error.user.maxlimit":"B\u1ea1n ch\u1ec9 c\u00f3 th\u1ec3 ch\u1ecdn t\u1ed1i \u0111a 20 ng\u01b0\u1eddi d\u00f9ng.",//no i18n
	"crm.las.error.picklist.maxlimit":"B\u1ea1n ch\u1ec9 c\u00f3 th\u1ec3 th\u00eam t\u1ed1i \u0111a 20 t\u00f9y ch\u1ecdn.",//no i18n

	"crm.fileuploader.message.responseerror": "T\u1ea3i l\u00ean th\u1ea5t b\u1ea1i", //NO I18N
	"crm.storage.create.error":"Kh\u00f4ng th\u1ec3 t\u1ea1o c\u00e1c b\u1ea3n ghi m\u1edbi v\u00ec b\u1ea1n \u0111\u00e3 \u0111\u1ea1t \u0111\u1ebfn gi\u1edbi h\u1ea1n l\u01b0u tr\u1eef d\u1eef li\u1ec7u t\u1ed1i \u0111a.",//no i18n
	"crm.storage.create.error.client":"Kh\u00f4ng th\u1ec3 t\u1ea1o b\u1ea3n ghi m\u1edbi v\u00ec qu\u1ea3n tr\u1ecb vi\u00ean c\u1ee7a b\u1ea1n \u0111\u00e3 \u0111\u1ea1t \u0111\u1ebfn gi\u1edbi h\u1ea1n l\u01b0u tr\u1eef t\u1ed1i \u0111a. Li\u00ean h\u1ec7 v\u1edbi {0} \u0111\u1ec3 gi\u1ea3i quy\u1ebft v\u1ea5n \u0111\u1ec1 n\u00e0y.",//no i18n
	"crm.storage.avail.info":"({0} c\u00f2n l\u1ea1i {1})",//no i18n
	"crm.storage.error.key.manage":"Qu\u1ea3n l\u00fd dung l\u01b0\u1ee3ng l\u01b0u tr\u1eef d\u1eef li\u1ec7u c\u1ee7a b\u1ea1n",//no i18n
	"Records":"B\u1ea3n ghi",//no i18n
	"crm.workflow.alert.additional.recipients" : "Th\u00eam Ng\u01b0\u1eddi nh\u1eadn", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "S\u1eed d\u1ee5ng d\u1ea5u ph\u1ea9y \u0111\u1ec3 t\u00e1ch nhi\u1ec1u \u0111\u1ecba ch\u1ec9 email b\u1ed5 sung.", //NO I18N
	"crm.related.contact.account" : "{0} li\u00ean quan \u0111\u1ebfn {1}",//No I18n
	"crm.allcontact.show" : "T\u1ea5t c\u1ea3 {0}",//No I18n
	"crm.button.mass.show" : "\u0110\u01b0a ra",//No I18n
	"crm.msg.custom.view.not.replied" : "C\u00e1c Tin Nh\u1eafn Kh\u00f4ng \u0110\u01b0\u1ee3c Tr\u1ea3 L\u1eddi", //NO I18N
	"crm.msg.custom.view.replied" : "C\u00e1c Tin Nh\u1eafn \u0110\u00e3 Tr\u1ea3 L\u1eddi",//NO I18N
	"crm.workflow.select.recipients" : "Ng\u01b0\u1eddi nh\u1eadn", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Vui l\u00f2ng ch\u1ecdn \u00edt nh\u1ea5t m\u1ed9t h\u1ed3 s\u01a1.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "R\u1ea5t ti\u1ebfc, nh\u01b0ng b\u1ea1n kh\u00f4ng th\u1ec3 x\u00f3a m\u1ed9t h\u1ed3 s\u01a1 m\u1eb7c \u0111\u1ecbnh.",//NO I18N
	"crm.inv.label.add.emails" : "Th\u00eam Email" ,//NO I18N
	"crm.prediction.analytics.filter.year":"N\u0103m Ngo\u00e1i",//no i18n
	"Previous\ FY":"FY tr\u01b0\u1edbc",//no i18n
	"Current\ FY":"FY hi\u1ec7n nay",//no i18n
	"Next\ FY":"FY ti\u1ebfp theo",//no i18n
	"Current\ FQ":"FQ hi\u1ec7n nay",//no i18n
	"Next\ FQ":"FQ ti\u1ebfp theo",//no i18n
	"Previous\ FQ":"FQ Tr\u01b0\u1edbc \u0111\u00f3",//no i18n
	"crm.inv.label.add.emails" : "Th\u00eam Email", //NO I18N
	"crm.picklist.sample.text":"V\u0103n B\u1ea3n M\u1eabu",//no i18n
	"crm.more.colors":"C\u00e1c M\u00e0u Kh\u00e1c",//no i18n
	"crm.button.back.alone":"Quay l\u1ea1i",//no i18n
	"crm.field.label.email":"Email",//no i18n
	"crm.zia.nba.feature.label":"H\u00e0nh \u0111\u1ed9ng th\u00edch h\u1ee3p nh\u1ea5t ti\u1ebfp theo",//no i18n
	"Meeting":"G\u1eb7p m\u1eb7t",//no i18n
	"Tomorrow":"Ng\u00e0y mai",//no i18n
	"crm.gdpr.notavailable.field":"Kh\u00f4ng Kh\u1ea3 D\u1ee5ng",//no i18n
	"crm.setup.system.ziasimilarity":"\u0110\u1ec1 ngh\u1ecb \u0111i\u1ec3m t\u01b0\u01a1ng t\u1ef1",//no i18n
	"crm.gdpr.notavailable.field":"Kh\u00f4ng Kh\u1ea3 D\u1ee5ng",//no i18n
	"crm.filter.label.all.products":"T\u1ea5t c\u1ea3 {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"crux.custom.field.greater.than.equalto":"{0} phải lớn hơn hoặc bằng {1}.",
	"crux.users.selected.plural" : "{0} Người Dùng Đã Chọn.",
	"crux.user.selected.singular" :"{0} Người Dùng Được Chọn.",
	"crux.criteria.empty.secondaryfield.module" : "Không tìm thấy trường {0} phù hợp trong module {1}",
	"crux.criteria.empty.secondaryfield" : "Không có trường {0} nào khác để so sánh, vui lòng nhập một giá trị để so sánh.",
	"crux.logged.in.role.definition" : "Vai trò người dùng bắt đầu thao tác bản ghi",
	"zia.similarity.smartfilter.score":"\u0110i\u1ec3m t\u01b0\u01a1ng \u0111\u1ed3ng",//no i18n
	"zia.similarity.smartfilter.records":"{0} t\u01b0\u01a1ng t\u1ef1 c\u1ee7a",//no i18n
	"zia.similarity.smartfilter.records.search":"Hi\u1ec3n th\u1ecb {0} t\u01b0\u01a1ng t\u1ef1 c\u1ee7a",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"B\u1ea1n kh\u00f4ng th\u1ec3 ch\u1ecdn nhi\u1ec1u h\u01a1n {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} phải lớn hơn hoặc bằng {1}.",
	"crux.users.selected.plural" : "{0} Người Dùng Đã Chọn.",
	"crux.user.selected.singular" :"{0} Người Dùng Được Chọn.",
	"crux.criteria.empty.secondaryfield.module" : "Không tìm thấy trường {0} phù hợp trong module {1}",
	"crux.criteria.empty.secondaryfield" : "Không có trường {0} nào khác để so sánh, vui lòng nhập một giá trị để so sánh.",
	"crux.logged.in.role.definition" : "Vai trò người dùng bắt đầu thao tác bản ghi",
	"crux.max.limit.unselect" : "Bạn không thể bỏ chọn nhiều hơn {0} {1}.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d đã được chọn" //NO I18N
}
